import MemoAPI from "services/memoAPI";
import _ from "lodash";

export const GET_CREATOR = "GET_CREATOR";
export const SET_CREATOR = "SET_CREATOR";

const func = () => {};
/**
 * 獲取 creator 資訊 
 * @param callback - (data, error)
 * @return {function(*)} - action
 */
export const getCreator = (creator = "", callback = func) => async (dispatch) => {

	if (!creator) {
		callback([], null);
		return dispatch({
			type: GET_CREATOR,
			payload: [],
		});
	}
	const creatorInfo = await MemoAPI.getCreatorInfo(creator);

	callback(creatorInfo, null);
	
	return dispatch({
		type: GET_CREATOR,
		payload: creatorInfo,
	});
};

/**
 * 更新 Creator 屬性
 * @param {object} creatorInfo
 * @param {function(*)} callback
 * @return {function(*)}
 */
export const setCreator = (creatorInfo, callback = func) => (dispatch) => {
	callback(creatorInfo, null);

	return dispatch({
		type: SET_CREATOR,
		payload: creatorInfo,
	});
};
