import React from 'react'
import Palette from 'theme/custom/palette'

function Redirect() {
    window.location.href = `${window.origin}/photo`

    return (
        <div
            className="h-100 w-100 position-absolute"
            style={{ background: Palette.light.light }}
        />
    )
}

export default Redirect
