import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "reducers";
import thunk from "redux-thunk";

export const history = createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)]; // 把middleware跟history資料串起來
const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

export default function configureStore(preloadedState) {
	const store = createStore(
		createRootReducer(history),
		preloadedState,
		composedEnhancers
	);

	if (module.hot) {
		module.hot.accept("reducers", () => {
			store.replaceReducer(createRootReducer(history));
		});
	}

	return store;
}
