const LocalStorageKey = {
	PhotoId: "PhotoId",
	Illustrator: "Illustrator",
	IllustratorID: "IllustratorID",
	Product:'Product',
	Photo: 'Photo',
	Mask:'Mask',
	CreatorEvent:'CreatorEvent',
	Cursor: 'Cursor',
	Language: 'Language',
};

export default LocalStorageKey;
