import _ from 'lodash'
import LSKey from 'constants/localStorageKey'
import { GET_PHOTO, SET_PHOTO } from 'actions/photo'

/**
 * photo: {
 *    list: {[id]: {contentObject}}
 * }
 * @param state
 * @param action
 * @return {*}
 */
const photoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PHOTO: {
            // 下載photo list
            const list = action.payload

            // no data
            if (_.isEmpty(list)) {
                return {
                    ...state,
                    list: {},
                    currentId: '',
                }
            }

            // set currentId
            const ids = Object.keys(list)
            const localPhotoId = localStorage.getItem(LSKey.PhotoId)
            const currentId = ids.includes(localPhotoId) ? localPhotoId : ids[0]

            return {
                ...state,
                list,
                currentId,
            }
        }

        case SET_PHOTO: {
            // new photo list
            const list = action.payload

            return {
                ...state,
                list,
            }
        }

        default:
            return state
    }
}

export default photoReducer
