import LSKey from "constants/localStorageKey";
import { CHANGE_LANG, GET_LINE_PROFILE, SET_REGION,SET_PRODUCT } from "actions/general";
import Helper from 'utils/helper';
import Config from '../constants/config'
// Languages
const storedLang = localStorage.getItem(LSKey.Language);
const browserLang = navigator.language.split(/[-_]/)[0]; // language without region code


const defaultState = {
	lang: storedLang || Helper.acceptLang(browserLang),
	profile: {},
};


const generalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case CHANGE_LANG: {
			localStorage.setItem(LSKey.Language, action.payload);

			return {
				...state,
				lang: action.payload,
			};
		}
		case SET_REGION: {
			Config.SET_COUNTRY = action.payload.region.country;
			return {
				...state,
				region: action.payload.region,
				lang: action.payload.lang,
			};
		}

		case GET_LINE_PROFILE: {
			return {
				...state,
				profile: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default generalReducer;
