import React from 'react'
import {
    createMuiTheme,
    ThemeProvider,
    responsiveFontSizes,
    fade,
    darken,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Color from 'color'
// Custom
import palette from './custom/palette'
import typography, {
    fontFamilyBrand,
    fontFamilyBody,
} from './custom/typography'
import {
    DarkShadow,
    BlackShadow,
    PrimaryShadow,
    LightShadow,
} from './custom/shadow'

// A custom theme
export const Theme = responsiveFontSizes(createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: fontFamilyBrand.join(','),
        ...typography,
    },
    palette,
    shadows: DarkShadow,
    shape: {
        borderRadius: 8,
    },
    zIndex: {
        body: 900,
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modalDrop: 1300,
        modal: 1400,
        snackbar: 1500,
        tooltip: 1600,
    },
    props: {
        MuiTooltip: {
            disableFocusListener: true,
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiRadio: {
            color: 'primary',
        },
    },
    overrides: {
        MuiAppBar: {
            positionFixed: {
                zIndex: 1100,
            },
            colorDefault: {
                color: palette.common.white,
                backgroundColor: palette.dark.dark,
            },
        },
        MuiTypography: {
            root: {
                margin: '',
            },
            button: {
                paddingLeft: '',
            },
            colorSecondary: {
                color: palette.grey.dark,
            },
            caption: {
                display: 'block',
            },
        },
        MuiTab: {
            root: {
                transition: '250ms color ease-in-out',
            },
            textColorPrimary: {
                '&:hover': {
                    color: palette.primary.main,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                ...typography.caption,
                color: palette.grey.dark,
                position: 'absolute',
                bottom: -20,
                left: 4,
                whiteSpace: 'nowrap',
            },
            contained: {
                left: 24,
                margin: 0,
            },
        },
        MuiModal: {
            root: {
                zIndex: 2000,
            },
        },
        MuiSwitch: {
            track: {
                backgroundColor: palette.grey.dark,
            },
        },
        MuiFormControlLabel: {
            labelPlacementStart: {
                marginLeft: 0,
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: Color(palette.grey.A400)
                    .alpha(0.9)
                    .string(),
            },
        },
        MuiChip: {
            root: {
                ...typography.caption,
                color: palette.dark.dark,
                backgroundColor: Color(palette.light.dark)
                    .alpha(0.5)
                    .string(),
                '&:hover': {
                    backgroundColor: Color(palette.light.dark)
                        .alpha(0.5)
                        .string(),
                },
                '&:focus': {
                    backgroundColor: Color(palette.light.dark)
                        .alpha(0.5)
                        .string(),
                },
            },
            colorPrimary: {
                color: palette.common.white,
                '&:hover': {
                    backgroundColor: Color(palette.primary.dark)
                        .alpha(1)
                        .string(),
                },
                '&:focus': {
                    backgroundColor: Color(palette.primary.dark)
                        .alpha(1)
                        .string(),
                },
            },
            deleteIcon: {
                transition: 'all 250ms ease',
                color: palette.grey.dark,
                opacity: 0.5,
                '&:hover': {
                    opacity: 1,
                    color: palette.error.main,
                },
            },
            deleteIconColorPrimary: {
                transition: 'all 250ms ease',
                color: palette.common.white,
                opacity: 0.5,
                '&:hover': {
                    opacity: 1,
                    color: palette.common.white,
                },
            },
            clickable: {
                cursor: 'pointer',
                transition: 'all 250ms ease-in-out',
                '&:hover': {
                    color: palette.common.white,
                    backgroundColor: Color(palette.primary.main)
                        .alpha(1)
                        .string(),
                },
                '&:focus': {
                    backgroundColor: Color(palette.primary.main)
                        .alpha(0.5)
                        .string(),
                },
            },
        },
        // Menu List Item
        MuiMenuItem: {
            root: {
                fontFamily: fontFamilyBrand.join(','),
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: 0.25,
                fontWeight: 400,
                minWidth: 80,
                textTransform: 'capitalize',
                overflow: 'visible',
                paddingTop: 12,
                paddingBottom: 12,
                '&$selected': {
                    color: palette.common.white,
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '150%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        backgroundColor: palette.dark.dark,
                    },
                },
            },
            selected: {},
        },
        MuiList: {
            root: {
                overflow: 'hidden',
            },
        },
        MuiListItem: {
            root: {
                zIndex: 0,
                padding: '12px 16px',
                '&$selected': {
                    backgroundColor: `transparent`,
                },
                selected: {},
            },
            button: {
                position: 'relative',
                transition: 'transform 250ms ease-in-out',
                '&:hover': {
                    color: palette.common.white,
                    backgroundColor: 'transparent',
                    transform: 'translateX(-4px)',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '150%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    transition: 'background-color 250ms ease-in-out',
                },
                '&:hover::before': {
                    backgroundColor: palette.dark.dark,
                },
            },
        },
        MuiFormLabel: {
            root: {
                // color: palette.dark.light,
            },
        },
        MuiSelect: {
            icon: {
                top: 'calc(50% - 16px)',
                // color: palette.dark.light,
            },
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
            iconOutlined: {
                top: 'calc(50% - 14px)',
            },
        },
        // TextField
        MuiInputLabel: {
            root: {
                whiteSpace: 'nowrap',
                textTransform: 'capitalize',
                pointerEvents: 'none',
                fontSize: 14,
                fontWeight: 400,
                fontFamily: fontFamilyBody.join(','),
                lineHeight: 1.5,
            },
            outlined: {
                transform: 'translate(12px, 6px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            multiline: {
                padding: '16px 0',
            },
            inputMultiline: {
                padding: '0 16px',
            },
            input: {
                padding: '0 16px',
            },
        },
        MuiNotchedOutline: {
            root: {
                // borderColor: palette.dark.light,
            },
        },
        MuiBadge: {
            colorPrimary: {
                color: palette.common.white,
            },
            badge: {
                whiteSpace: 'nowrap',
                ...typography.caption,
            },
        },
        MuiInputAdornment: {
            root: {
                whiteSpace: 'nowrap',
            },
            filled: {
                '&$positionStart': {
                    marginRight: 8,
                    marginTop: 'unset !important',
                },
            },
            positionStart: {},
        },
        MuiInputBase: {
            root: {
                fontSize: '1rem',
                fontWeight: 400,
                fontFamily: fontFamilyBody.join(','),
                lineHeight: 1.5,
                minHeight: 32,
            },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: `1px solid ${palette.grey.main}`,
                },
            },
        },
        MuiFilledInput: {
            root: {
                ...typography.body1,
                fontWeight: 'bold',
                color: palette.dark.dark,
                height: 48,
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                borderRadius: 24,
                backgroundColor: palette.common.white,
                boxShadow: LightShadow[4],
                transition: '250ms ease-in-out all',
                '&:hover': {
                    boxShadow: LightShadow[8],
                    backgroundColor: `${palette.common.white} !important`,
                },
                '& ::placeholder': {
                    ...typography.body1,
                    color: palette.grey.main,
                    fontWeight: 'bold',
                    opacity: 1,
                },
                '&$focused': {
                    color: palette.primary.main,
                    boxShadow: LightShadow[8],
                    backgroundColor: palette.common.white,
                },
                '&$disabled': {
                    backgroundColor: palette.grey.light,
                    pointerEvents: 'none',
                },
            },
            input: {
                borderRadius: '0 !important',
                padding: 'inherit',
            },
            focused: {},
            disabled: {},
            adornedStart: {
                paddingLeft: 24,
            },
            adornedEnd: {
                paddingRight: 24,
            },
        },
        // Button
        MuiButton: {
            root: {
                color: palette.grey.dark,
                padding: '6px 16px',
                borderRadius: 9999,
                whiteSpace: 'nowrap',
                lineHeight: '20px',
                '&:hover': {
                    color: palette.primary.main,
                    backgroundColor: fade(
                        palette.primary.main,
                        palette.action.hoverOpacity
                    ),
                },
            },
            text: {
                padding: '6px 16px',
            },
            outlined: {
                padding: '5px 15px',
                border: `1px solid ${fade(palette.dark.dark, 0.23)}`,
                '&:hover': {
                    color: palette.primary.main,
                    border: `1px solid ${palette.primary.main}`,
                    backgroundColor: fade(
                        palette.primary.main,
                        palette.action.hoverOpacity
                    ),
                },
            },
            outlinedSecondary: {
                color: palette.dark.dark,
                border: `1px solid ${fade(palette.dark.dark, 0.23)}`,
                '&:hover': {
                    color: darken(palette.dark.dark, 0.5),
                    border: `1px solid ${palette.grey.dark}`,
                    backgroundColor: fade(
                        palette.grey.dark,
                        palette.action.hoverOpacity
                    ),
                },
            },
            textSecondary: {
                color: palette.dark.dark,
                '&:hover': {
                    color: darken(palette.dark.dark, 0.5),
                    backgroundColor: fade(
                        palette.dark.dark,
                        palette.action.hoverOpacity
                    ),
                },
            },
            contained: {
                color: palette.grey.dark,
                backgroundColor: palette.common.white,
                '&:hover': {
                    color: palette.primary.main,
                    backgroundColor: palette.common.white,
                },
            },
            containedPrimary: {
                color: palette.common.white,
                '&:hover': {
                    color: palette.common.white,
                },
            },
            containedSecondary: {
                backgroundColor: palette.dark.dark,
                '&:hover': {
                    color: palette.common.white,
                    backgroundColor: darken(palette.dark.dark, 0.5),
                },
            },
            iconSizeMedium: {
                '& > svg': {
                    width: 20,
                    height: 20,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: 12,
                color: palette.grey.dark,
                '&:hover': {
                    color: palette.primary.main,
                    backgroundColor: fade(
                        palette.primary.main,
                        palette.action.hoverOpacity
                    ),
                },
            },
            colorSecondary: {
                color: palette.dark.dark,
                '&:hover': {
                    color: darken(palette.dark.dark, 0.5),
                },
            },
            sizeSmall: {
                padding: 6,
                '& svg': {
                    width: 20,
                    height: 20,
                },
            },
        },
        MuiToggleButton: {
            root: {
                '&$selected': {
                    color: palette.common.white,
                    backgroundColor: palette.primary.main,
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                    },
                },
                transition: 'background-color 150ms ease-in-out',
            },
            selected: {},
        },
        MuiCheckbox: {
            root: {
                color: palette.grey.dark,
            },
        },
        MuiStepIcon: {
            text: {
                fill: palette.common.white,
            },
        },
        // Panel Collapse
        MuiExpansionPanel: {
            root: {
                boxShadow: DarkShadow[1],
                '&$expanded': {
                    boxShadow: DarkShadow[4],
                },
            },
            expanded: {},
        },
        MuiExpansionPanelSummary: {
            root: {
                padding: '0 16px',
            },
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0 16px 24px',
            },
        },
        MuiCollapse: {
            entered: {
                overflow: 'visible',
            },
        },
        // Table
        MuiTableCell: {
            root: {
                position: 'relative',
                whiteSpace: 'nowrap',
                height: 48,
                padding: '8px 16px',
            },
            head: {
                // color: palette.dark.light,
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontFamily: fontFamilyBrand,
            },
        },
        MuiTableSortLabel: {
            root: {
                transition: {
                    property: 'color',
                    duration: '250ms',
                    timingFunction: 'ease-in-out',
                },
                '&:hover': {
                    color: palette.primary.light,
                },
                '&:focus': {
                    color: palette.primary.main,
                },
            },
            active: {
                color: palette.primary.main,
            },
            icon: {
                color: `${palette.primary.main} !important`,
            },
        },
        // Dialog
        MuiDialog: {
            paper: {
                boxShadow: BlackShadow[12],
            },
        },
        MuiDialogContent: {
            root: {
                overflowY: 'overlay',
            },
        },
        // Pickers
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: palette.dark.dark,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: palette.dark.dark,
                color: palette.common.white,
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: palette.common.white,
            },
            toolbarBtnSelected: {
                color: palette.primary.main,
            },
        },
        PrivateTabIndicator: {
            colorSecondary: {
                backgroundColor: palette.primary.main,
            },
        },
        MuiPickersCalendarHeader: {
            transitionContainer: {
                '& p': {
                    lineHeight: '20px',
                },
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: palette.grey.main,
            },
        },
        MuiPickersClock: {
            clock: {
                backgroundColor: palette.grey[50],
            },
        },
        MuiPickersYear: {
            root: {
                color: palette.grey.dark,
                '&:hover': {
                    color: palette.primary.light,
                },
            },
            yearSelected: {
                color: palette.primary.main,
            },
        },
    },
}))

const MuiTheme = props => (
    <ThemeProvider theme={Theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
    </ThemeProvider>
)

export default MuiTheme
