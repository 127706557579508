import ReactGA from "react-ga";

export const Category = {
	CREATOR: "CREATOR",
	HOME: "HOME",
	PHOTO: "PHOTO",
	EDIT: "EDIT",
	CHECKOUT: "CHECKOUT",
};

export const Action = {
	// CREATOR
	creatorId: "creator id",
	creator: "creator",

	// PHOTO
	clickNext: "click next",
	clickUploadType: "click upload type",

	// EDIT
	searchFrameType: "search frame type",
	searchKeyword: "search keyword",
	clickIllustrator: "click illustrator",
	clickTabs: "click tabs",
	clickPhoto: "click photo",
	clickPhotoFrame: "click photo frame",
	clickPhotoType: "click photo type",
	clickPhotoCenter: "click photo center",
	clickPhotoRotate: "click photo rotate",
	toggleDialog: "toggle Dialog",
	resetPhoto: "reset photo",
	resetFrame: "reset frame",
	exportPhoto: "export photo",
	exportPhotoAgain: "export photo again",
	goToCheckout: "go to checkout",
	backToPhoto: "back to photo",

	// CHECK
	backToEdit: "back to edit",
	clickCheckoutButton: "click checkout button",
	clickPayment: "click payment",
	goPhotoPage: "click go to photo page button",
	clickCanclePayment: "click cancle payment",

	// CHECK
	finish: "FINISH",

	openStory: "Open Story",
};

export const gaInit = (trackingId) => {
	ReactGA.initialize(trackingId);
	// 加入電子商務套件
	ReactGA.plugin.require("ecommerce");
};

export const gaPageView = () => {
	ReactGA.pageview(window.location.pathname + window.location.search);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
export const gaEvent = (category, action, label, value) => {
	ReactGA.event({
		category,
		action,
		label: `${label}`,
		value,
	});
};

export const ecommerce = (id, quantity = 0, price = 0, product) => {
	ReactGA.plugin.execute("ecommerce", "addItem", {
		id, // Transaction ID. Required.
		name: product === '' ? 'Photo' : product, // Product name. Required.
		sku: "photo_sku", // SKU/code.
		category: "Creator", // Category or variation.
		price,
		quantity,
	});

	ReactGA.plugin.execute("ecommerce", "addTransaction", {
		id, // the same as for addItem to connect them
		revenue: quantity * price, // obviously it's price * quantity
	});
	ReactGA.plugin.execute("ecommerce", "send");
	ReactGA.plugin.execute("ecommerce", "clear");
};
