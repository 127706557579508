/* Global Style */
import "assets/sass/memopresso.scss";
import "assets/css/font.css";

import React from "react";
import ReactDOM from "react-dom";

import {
	StylesProvider,
	createGenerateClassName,
	jssPreset,
} from "@material-ui/core/styles";
import MuiTheme from "theme/MuiTheme"; // material-ui theme

// JSS
import { create } from "jss";
import jssExpand from "jss-expand";
import jssCompose from "jss-compose";

// Redux
import {
	Provider,
	ReactReduxContext,
} from "react-redux"; /* react bindings for redux */
import Store, { history } from "store/store";
import { ConnectedRouter } from "connected-react-router";
import { setRegion } from "actions/general";
import App from "./containers/app/App";

import ConnectedIntlProvider from "containers/intlProvider/IntlProvider";

// 增加JSS plugins
const jss = create({
	plugins: [...jssPreset().plugins, jssCompose(), jssExpand()],
});

const generateClassName = createGenerateClassName();

const store = Store(/* provide initial state if any */);
store.dispatch(setRegion());//取得使用者地區

ReactDOM.render(
	<Provider store={store} context={ReactReduxContext}>
		<ConnectedRouter history={history} context={ReactReduxContext}>
			<ConnectedIntlProvider>
				<StylesProvider jss={jss} generateClassName={generateClassName}>
					<MuiTheme>
						<App />
					</MuiTheme>
				</StylesProvider>
			</ConnectedIntlProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root")
);
