import _ from 'lodash'
import LSKey from 'constants/localStorageKey'
import { GET_PHOTO, SET_PHOTO } from 'actions/photo'
import { SET_MASK } from 'actions/mask'

/**
 * photo: {
 *    list: {[id]: {contentObject}}
 * }
 * @param state
 * @param action
 * @return {*}
 */
const maskReducer = (state = {}, action) => {
    switch (action.type) {

        case SET_MASK: {
            // new photo list
            const list = action.payload

            return list
        }

        default:
            return state
    }
}

export default maskReducer
