import React, { useEffect, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom"; // react-router v5
import { ToastContainer } from "react-toastify";
import Config from "constants/config";
import { gaInit, gaPageView } from "utils/ga";


// Components
import Redirect from "components/Redirect";

// Containers
const Navigation = lazy(() => import("containers/navigation/Navigation"));

// Containers For Public
const Guideline = lazy(() => import("containers/guideline/Guideline"));



const App = () => {
	useEffect(() => {
		const host = window.location.hostname;
		gaInit(
			host === "localhost" || host === "dev.memopresso.com"
				? Config.GA_ID_DEV
				: Config.GA_ID
		);
		gaPageView();
	}, []);

	return (
		<div
			className="container-fluid"
			style={{
				width: "100vw",
				minHeight: "100vh",
				padding: 0,
				overflow: "hidden",
			}}
		>
			<Suspense fallback={<div />}>
				<Switch>
					{/*<Route exact path="/" component={Redirect} />*/}
					<Route path="/guideline" component={Guideline} />

					{/* Platform */}
					<Route path="/" component={Navigation} />
				</Switch>
			</Suspense>

			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
				style={{ zIndex: 9999 }}
			/>
		</div>
	);
};

export default App;
