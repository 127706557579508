import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import Translations from "translations";

if (!Intl.PluralRules) {
	require("@formatjs/intl-pluralrules/polyfill");
	require("@formatjs/intl-pluralrules/dist/locale-data/de"); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
	require("@formatjs/intl-relativetimeformat/polyfill");
	require("@formatjs/intl-relativetimeformat/dist/locale-data/de"); // Add locale data for de
}

const mapStateToProps = (state) => {
	const { general = {} } = state;
	const { lang } = general;

	const messages = Translations[lang.replace('-', '_')];
	return { locale: lang, messages };
};

export default connect(mapStateToProps)(IntlProvider);
