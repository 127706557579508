const ErrorType = {
    default: 0,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    internalError: 500,
    noService: 503,
}

const Language = {
    EN: 'en',
    TW: 'zh-tw',
    TH: 'th',
}
const Country = {
    TW: 'TW',
    TH: 'TH',
    KR: 'KR',
}


export { ErrorType, Language, Country }
