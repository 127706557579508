/* eslint-disable no-template-curly-in-string */
let th = {
	"photo.selected": "เลือก {number} รูปแล้ว",
	"photo.next": "ถัดไป",
	"photo.noPhotos": "ยังไม่ได้อัพโหลดรูป",
	"photo.select.limit": "กรุณาเลือกอย่างน้อย 2 รูป อย่างมาก 4 รูป",
	"source.image": "รูปภาพ",
	"upload.file": "อัพโหลดรูป",
	"upload.newest": "อัพโหลดล่าสุด",
	// action
	"action.cancel": "ยกเลิก",
	"action.ok": "ok",
	"action.next": "ถัดไป",
	"action.back": "ก่อนหน้า",
	"action.finish": "เรียบร้อย",
	"action.select.all": "เลือกทั้งหมด",
	"action.Home": "หน้าแรก",
	// edit
	"edit.title": "แก้ไขรูป",
	"edit.tab.property": "คุณสมบัติ",
	"edit.tab.frame": "กรอบรูป",
	"edit.tab.text": "แคปชั่น",
	"edit.tab.location": "โลเคชั่น",
	"edit.tab.date": "วันที่",
	"edit.frameLoadmore": "ดูกรอบรูปเพิ่ม",
	"edit.reset": "รีเซ็ต",
	"edit.layout": "เลย์เอ้าท์",
	"edit.straight": "แนวตั้ง",
	"edit.horizontal": "แนวนอน",
	"edit.full": "เต็มรูป",
	"edit.center": "ตรงกลาง",
	"edit.rotate": "หมุน",
	"edit.edit": "แก้ไข",
	"edit.editText": "แก้ไขแคปชั่น",
	"edit.editLocation": "แก้ไขโลเคชั่น",
	"edit.editDate": "แก้ไขวันที่",
	"edit.show": "แสดง",
	"edit.hide": "ซ่อน",
	"edit.wordLimit": "จำกัดจำนวนคำ",
	"edit.clear": "ล้าง",
	"edit.progress": "กำลังประมวลผล({current}/{total})",
	"edit.disable.frame": "เลย์เอ้าท์นี้ไม่มีกรอบรูป",
	"edit.frame.disable.text": "เลย์เอ้าท์หรือกรอบรูปนี้ไม่สามารถแก้ไขแคปชั่นได้",

	// Home
	"loadmore": "ดูเพิ่ม",

	// frame
	"frame.title": "ค้นหากรอบรูป",
	"frame.search": "ค้นหา",
	"frame.enterKeywords": "ใส่คีย์เวิร์ด",
	"frame.hotIllustrators": "นักวาดยอดนิยม",
	"frame.hotKeywords": "คีย์เวิร์ดยอดนิยม",
	"frame.illResult": "ผลการค้นหานักวาด",
	"frame.keywordResult": "ผลการค้นหาคีย์เวิร์ด",
	"frame.hot": "ฮอตฮิต",
	"frame.new": "ใหม่ล่าสุด",
	"frame.random": "สุ่ม",
	"frame.noResult": "ไม่มีผลการค้นหา",
	"frame.popular": "ยอดนิยม",

	// checkout
	"checkout.title.frame": "ยืนยันรูป",
	"checkout.title.mask": "Mask Container",
	"checkout.title.creatorEvent": "Card",
	"checkout.terms": "เงื่อนไข",
	"checkout.payment": "วิธีการชำระเงิน",
	"checkout.receipt": "ใบเสร็จรับเงิน",
	"checkout.coupon": "รหัสโปรโมชั่น",
	"checkout.coupon.placeholder": "กรุณาข้ามถ้าไม่มี",
	"checkout.coupon.verify": "ตรวจสอบ",
	"checkout.coupon.error": "คูปองไม่ถูกต้อง",
	"checkout.coupon.used": "คูปองถูกใช้แล้ว",
	"checkout.coupon.machine.only": "คู่ปองสำหรับใช้ที่ตู้ปริ๊นท์เท่านั้น",
	"checkout.coupon.discount": "ส่วนลด: {discount}",
	"checkout.coupon.point": "MP point : {point}",
	"checkout.coupon.buy2get2": "นี่เป็นคูปอง 1 แถม 1 กรุณาเลือกรูปเพิ่มอีก 2 รูป",
	"checkout.unreached.discount": "Unreached discount amount",
	"checkout.coupon.exceed.limit": "exceed the limit",
	"checkout.coupon.expired": "coupon has expired",
	"checkout.coupon.new.user.only": "coupon only for new user",
	"checkout.priceTitle": "ยอดซื้อทั้งหมด",
	"checkout.price": "{price_unit}{price} / รวม {number} รูป",
	"checkout.photo.quantity": "รวม {number} แผ่น",
	"checkout.photo.price": "รูปภาพ {price_unit}{price}",
	"checkout.shipping.fee": "ค่าส่ง {price_unit}{shippingFee} (ส่งฟรีเมื่อซื้อครบ {price_unit}{minimum} บาท)",
	"checkout.free.shipping": "Free Shipping",
	"checkout.no.event.frame":"Please select activity frame",
	"checkout.serialcode": "หมายเลขสำหรับปริ๊นท์คือ",
	"checkout.save.serialcode": "กรุณาจดหรือเซฟหมายเลขเลขนี้ไว้",
	"checkout.save.serialcode.print": "กรุณา{highlight}จดหรือเซฟหมายเลขของคุณไว้{br}เพื่อนำไปใช้ปริ๊นที่ตู้!",
	"checkout.gophotopage": "กลับไปที่หน้ารูปภาพ",
	"checkout.gohomepage": "กลับไปที่หน้าแรก",
	"checkout.sendMail": "ส่งอีเมล",
	"checkout.enterEmail": "ใส่อีเมล",
	"checkout.email": "อีเมล",
	"checkout.send": "ส่ง",
	"checkout.cannot.getEphoto": "ต้องชำระเงินเสร็จก่อน ถึงสามารถขอไฟล์ได้",
	"checkout.emailWrongFormat": "รูปแบบอีเมลไม่ถูกต้อง",
	"checkout.sendEmailSuccess": "ส่งอีเมลสำเร็จแล้ว",
	"checkout.enterText": "กรอกตัวอักษร",
	"checkout.checkout": "ชำระเงิน",
	"checkout.linepay": "Linepay",
	"checkout.jkopay": "JKOPay",
	"checkout.creditcard": "บัตรเครดิต",
	"checkout.ATM": "โอนผ่านATM",
	"checkout.easypay": "Easy Wallet",
	"checkout.onsite": "onsite",
	"checkout.mp.point": "MP POINT",
	"checkout.current.mp.point": "คะแนนปัจจุบัน",
	"checkout.tutorial.step": "ขั้นตอน",
	"checkout.button.send": "ส่ง",
	"checkout.ThaiQR":"พร้อมเพย์",
	"checkout.thaiQR.title": "กรุณาบันทึกภาพหน้าจอนี้ (screenshot)",
	"checkout.thaiQR.serialNumber": "Serial No:{serialNumber}",
	"checkout.thaiQR.steps":
		`1.  เปิดแอพธนาคาร หรือ True Money Wallet สแกน QRCode ด้านล่างเพื่อชำระเงิน {amount} บาท เมื่อชำระเงินเรียบร้อย ระบบจะอัพเดทว่ารายการเสร็จสมบูรณ์โดยอัตโนมัติ
		{br}
		2. ตรวจสอบสถานะคำสั่งซื้อและการจัดส่งได้ใน Line ID: @fotoya
		{br}
		3. ฟอลโล่ไอจี fotoya.creators เพื่อลุ้นปริ๊นท์รูปฟรี`,
	"checkout.thaiQR.price": "คุณสั่งซื้อรูป {quantity} รูป ราคา {price} บาท",
	"checkout.thaiQR.shippingFee": "ค่าส่ง ราคา {shippingFee} บาท",
	"checkout.thaiQR.amount": "รวม {amount} บาท",
	"checkout.event.ran.out":"Free print vouchers already ran out",
	"checkout.event.only.once":"Only print once for free",

	// alert
	"alert.reset.title": "รีเซ็ตรูป",
	"alert.reset.message": "แน่ใจว่าต้องการรีเซ็ต?",
	"alert.select.even.photos": "กรุณาเลือกรูปภาพจำนวนคู่",
	"alert.select.4.photos": "กรุณาเลือกรูปจำนวนเท่าของ 4",
	"alert.select.max.photos": "เลือกได้มากที่สุด{maximum}รูป",
	"alert.insufficient.balance": "จำนวนเงินไม่พอ กรุณาเติมเงิน",
	"alert.delivery.user.info.empty": "ข้อมูลทั้งหมดไม่สามารถเว้นว่าง",
	"alert.delivery.mobile.wrong.format": "รูปแบบเบอร์มือถือไม่ถูกต้อง (ใช้รูปแบบ 0xxxxxxxxx)",
	"alert.select.photo.empty": "กรุณาเลือกรูป",
	"alert.select.mask.empty": "Please select mask container style",
	"alert.select.data.empty": "Please select items",
	//illustrator
	"illustrator.no.frame": "ไม่มีกรอบรูป",
	"illustrator.no.mask": "No face mask container",
	"illustrator.no.data": "ไม่มีข้อมูล",
	"illustrator.goto.frame": "ทำรูปเพื่อปริ๊นท์",
	"illustrator.goto.mask":"Make Container",
	"illustrator.goto.location": "โลเคชั่นตู้ปริ๊นท์",
	"illustrator.reviews": "รีวิว",
	"illustrator.frame": "กรอบรูป",
	"illustrator.mask": "Mask container",
	"illustrator.card": "Cards",
	"illustrator.goto.card":"Make Cards",

	// Footer
	"footer.about": "เกี่ยวกับ Fotoya",
	"footer.intro":
		"โซเชียลมีเดีย ผสมผสานการรวมตัวกันระหว่างซอฟต์แวร์และฮาร์ดแวร์ Fotoya ได้สร้างเทคโนโลยีอัตโนมัติ บวกกับความร่วมมือจากนักวาดภาพประกอบและครีเอเตอร์ เราพร้อมส่งมอบวิธีการบันทึก สรรค์สร้าง และแบ่งปันความทรงจำ ประสบการณ์ ถ่ายทอดมาเป็นรูปภาพที่แสนประทับใจ",
	"footer.guideTitle": "แนะนำเวปไซต์",
	"footer.guide.about": "เกี่ยวกับ",
	"footer.guide.use": "วิธีใช้งาน",
	"footer.guide.location": "โลเคชั่นตู้ปริ๊นท์",
	"footer.guide.hotCreator": "นักวาดยอดนิยม",
	"footer.guide.business": "ร่วมมือทางธุรกิจ",
	"footer.guide.become.creator": "เริ่มต้นเป็นครีเอเตอร์",
	"footer.guide.lang": "ภาษา",
	"footer.contact.tel": "ติดต่อฝ่ายบริการลูกค้า",
	"footer.contact.mail": "อีเมลฝ่ายบริการลูกค้า: ",

	//Creator
	"creator.title": "พื้นที่สำหรับครีเอเตอร์",
	"creator.search": "ค้นหาครีเอเตอร์",
	"creator.weekHot": "ฮอตฮิตประจำสัปดาห์",
	"creator.monthHot": "ฮอตฮิตประจำเดือนนี้",
	"creator.new": "มาใหม่",
	"creator.recommend": "แนะนำ",
	"creator.halloween": "ฮัลโลวีน",
	"creator.christmas": "คริสมาสต์",
	"creator.new.year": "ปีใหม่",
	"creator.graduation": "จบการศึกษา",
	"creator.ghost": "鬼月專區",
	"creator.mother": "วันแม่",
	"creator.father": "วันพ่อ",
	"creator.others": "อื่นๆ",
	"creator.rainbow": "Rainbow again",
	"creator.love": "รักหวานๆ",
	"creator.copy": "คัดลอกลิงค์",
	"creator.copySuccess": "คัดลอกสำเร็จ",
	"creator.shareFacebook": "แชร์ไป Facebook",

	//Manual
	"manual.title": "วิธีการใช้งาน",
	"manual.step1": "เข้าสู่ระบบ LINE เลือกรูปภาพและกรอบรูป",
	"manual.step2": "ชำระเงินและรับหมายเลขสำหรับปริ๋นท์",
	"manual.step3": "Go to the nearby machine and enter the serial number",
	"manual.step4": "Get your photo",
	"manual.detail": "Detail Tutorial",

	// Location
	"location.title": "Machine Location",
	"location.openMap": "Open map",

	//Delivery 
	"delivery.option": "วิธีการจัดส่ง",
	"delivery.delivery": "บริษัทขนส่ง",
	"delivery.self": "Self",
	"delivery.input.name": "ชื่อ",
	"delivery.input.phoneNumber": "หมายเลขโทรศัพท์",
	"delivery.input.address": "ที่อยู่",
	"delivery.input.error.phoneNumber": "Error Phone Number",
	"delivery.input.remarks": "Remarks",

	//Order
	"order.information": "ข้อมูลการสั่งซื้อ",
	"order.quantity": "จำนวนรูป",
	"order.photo.price": "ค่ารูป",
	"order.shippingfee": "ค่าขนส่ง",
	"order.total.price": "ราคารวม",
	"order.query": "ขั้นตอนการสั่งซื้อ",
	"order.recipient": "ผู้รับ",
	"order.address": "ที่อยู่ผู้รับ",
	"order.number": "หมายเลขใบสั่งซื้อ",
	"order.delivery.time": "รูปภาพจะถูกจัดส่งใน 3 วัน",
	"order.receipt.donate": "捐贈",
	"order.receipt.no.donate": "不捐贈",
	"order.receipt.no.setting": "請在機台付款時輸入資訊",


	//Reviews
	"reviews.no.data": "ไม่มีข้อมูล",
	"reviews.no.reviews": "ยังไม่มีรีวิว",
	"reviews.select.frame": "เลือกกรอบที่ต้องการรีวิว",
	"reviews.content": "รีวิว",
	"reviews.selectframe.or.selectall": "กรุณาเลือกกรอบที่ต้องการรีวิวหรือเลือกทั้งหมด",
	"reviews.contiune": "ต้องการรีวิวกรอบรูปอื่นต่อหรือไม่",
	"reviews.yes": "ต้องการ",
	"reviews.no": "ไม่ต้องการ",
	"reviews.finish": "รีวิวกรอบรูปเสร็จแล้ว",
	"reviews.content.empty": "กรุณากรอกข้อความหรือเลือกข้อความที่ตั้งไว้",
	"reviews.star": "กรุณาเลือกจำนวนดาว",
	"reviews.default.cute": "กรอบน่ารักมาก",
	"reviews.default.like": "ชอบมากๆ",
	"reviews.default.useful": "มีประโยชน์มาก",
	"reviews.content.placeholder": "กรุณาเขียนรีวิว",

	//ZipCodeSelect
	"zipCodeSelect.zipCode": "รหัสไปรษณีย์",
};

export default th;
