import Config, { ENV } from "constants/config";
//API
import MemoAPI from "services/memoAPI";

const func = () => {};
const liff = window.liff || func;

let isInit = false;
let profile = {};
let liffInfo = {};

class LiffHelper {
	constructor() {
		//this.init(); 
	}

	init() {
		return new Promise((resolve, reject) => {
			if (!isInit) {
				liff.init(
					{
						liffId: ENV.isProd
							? Config.LINE_ID
							: Config.LINE_ID
					},
					(data) => {
						liffInfo = data;
						//isInit = true;
						resolve();
					},
					(err) => {
						console.log(
							"Fail to init LIFF, please run inside LINE only"
						);
						
						window.location.replace(
							`${window.location.origin}/photo`
						);
						reject();
					}
				);
			} else {
				resolve();
			}
		});
	}

	getProfile() {
		return new Promise((resolve, reject) => {
			if (liff.isInClient()) {
				if (!liff.isLoggedIn()) {
					liff.login({
						redirectUri: `${window.location.origin}/photo${window.location.search}`,
						//redirectUri: `${window.location.href}`,
					});
					resolve(profile);
				} else {
					liff.getProfile()
						.then((pf) => {
							profile = pf;
							//get email and update
							const idToken = liff.getDecodedIDToken();
							if(idToken !== null)
							{
								MemoAPI.updateUserInfo(idToken);
								profile.email = idToken.email
							}
							else
								profile.email = "";
							resolve(profile);
						})
						.catch((err) => {
							console.log("get profile error", err);
							reject(err);
						});
				}
			} else {
				this.init()
					.then(() => {
						if (!liff.isLoggedIn()) {
							liff.login({
								//redirectUri: `${window.location.href}`,
								redirectUri: `${window.location.origin}/photo${window.location.search}`,
							});
							resolve(profile);
						} else {
							liff.getProfile()
								.then((pf) => {
									profile = pf;
									//get email and update
									const idToken = liff.getDecodedIDToken();
									if(idToken !== null)
									{
										MemoAPI.updateUserInfo(idToken);
										profile.email = idToken.email
									}
									else
										profile.email = "";
									resolve(profile);
								})
								.catch((err) => {
									console.log("get profile error", err);
									reject(err);
								});
						}
					})
					.catch((err) => {
						reject(err);
					});
			}
		});
	}

	closeWindow() {
		liff.closeWindow();
	}

	openWindow(url, external) {
		liff.openWindow({ url, external });
	}

	getEmail()
	{
		const idToken = liff.getDecodedIDToken();							
		//console.log(idToken)
		return idToken === null ? '' : idToken.email;
	}

	sendMessages(messages) {
		if (!liff.isInClient())
			//之後可以改用推播的方式
			return;

		return new Promise((resolve, reject) => {
			this.init()
				.then(() => {
					liff.sendMessages([
						{
							type: "text",
							text: messages,
						},
					])
						.then(() => {
							resolve();
						})
						.catch((err) => {
							reject(err);
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
export default new LiffHelper();
