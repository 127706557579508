import React from "react";
import { FormattedMessage } from "react-intl";
import { Country } from './general'
import ZipCodeTW from './ZipCode/TW';
import ZipCodeTH from './ZipCode/TH';

const LayoutStyle = {
	Straight: "Straight",
	Horizontal: "Horizontal",
	Full: "Full",
	Horizontal4x6: "Horizontal4x6",
};

const TabIndex = {
	Property: 0,
	Frame: 1,
	Text: 2,
	Location: 3,
	Date: 4,
};

const FormType = {
	Text: "Text",
	Location: "Location",
	Date: "Date",
};

const FrameType = {
	HOT: "HOT",
	NEW: "NEW",
	SEARCH: "SEARCH",
	RANDOM: "RANDOM",
	EVENT: "EVENT",
	ILLUSTRATOR: "ILLUSTRATOR",
	USERNAME: "USERNAME",
};

const Payment = {
	NOPAY: "NOPAY",
	CREDITCARD: "CREDITCARD",
	ATM: "ATM",
	LINEPAY: "LINEPAY",
	JKOPAY: "JKOPAY",
	WALLET: "WALLET",
	EASYWALLET: "EASYWALLET",
	THAIQR: "THAIQR"
};

const DeliveryOption = {
	DELIVERY: 'DELIVERY',
	SELF: 'SELF',
};

const Product = {
	Frame: 'frame',
	//Mask: 'mask',
	Card: 'card'
}
const CountryAdminDivision = (country) => {
	switch (country) {
		case Country.TH:
			return ZipCodeTH;
		default:
			return ZipCodeTW;
	}
}
const CountryAdminDivisionLevels = (country) => {
	switch (country) {
		case Country.TH:
			return ['จังหวัด', 'อำเภอ/เขต/กิ่งอำเภอ', 'ตำบล'];
		default:
			return ['縣市', '鄉鎮市區'];
	}
}
const CountryDeliveryOption = (country) => {
	switch (country) {
		case Country.TH:
			return [
				{
					value: DeliveryOption.DELIVERY,
					text: <FormattedMessage id="delivery.delivery" defaultMessage="delivery" />,
				},
			];
		default:
			return [
				{
					value: DeliveryOption.SELF,
					text: <FormattedMessage id="delivery.self" defaultMessage="self" />,
				},
				{
					value: DeliveryOption.DELIVERY,
					text: <FormattedMessage id="delivery.delivery" defaultMessage="delivery" />,
				},
			];
	}
}
const CountryPayments = (country) => {
	switch (country) {
		case Country.TH:
			return [
				{
					value: Payment.THAIQR,
					text: (
						<FormattedMessage
							id="checkout.ThaiQR"
							defaultMessage="Thai QR Payment"
						/>
					),
				},
			]

		default:
			return [
				{
					value: Payment.NOPAY,
					text: (
						<FormattedMessage
							id="checkout.onsite"
							defaultMessage="Onsite"
						/>
					),
				},
				{
					value: Payment.LINEPAY,
					text: (
						<FormattedMessage
							id="checkout.linepay"
							defaultMessage="Linepay"
						/>
					),
				},
				{
					value: Payment.JKOPAY,
					text: (
						<FormattedMessage
							id="checkout.jkopay"
							defaultMessage="JKOPay"
						/>
					),
				},
				{
					value: Payment.CREDITCARD,
					text: (
						<FormattedMessage
							id="checkout.creditcard"
							defaultMessage="credit card"
						/>
					),
				},
				{
					value: Payment.ATM,
					text: <FormattedMessage id="checkout.ATM"
						defaultMessage="ATM" />,
				},
				{
					value: Payment.EASYWALLET,
					text: (
						<FormattedMessage
							id="checkout.easypay"
							defaultMessage="Easy Wallet"
						/>
					),
				},
				{
					value: Payment.WALLET,
					text: (
						<FormattedMessage
							id="checkout.mp.point"
							defaultMessage="MP POINT"
						/>
					),
				},
			];
	}
}

export { LayoutStyle, Product, CountryDeliveryOption, CountryAdminDivision, CountryAdminDivisionLevels, CountryPayments, TabIndex, FormType, FrameType, Payment, DeliveryOption };
