import _ from "lodash";
import ChangeCase from "change-case";
import Config, { ENV } from "constants/config";
import { Country } from "constants/general";
import Html2canvas from "html2canvas";
import { isValidPhoneNumber } from 'libphonenumber-js'

import Translations from "../../src/translations/index";

const Order = {
	ASC: "asc",
	DESC: "desc",
};

const func = (props) => props;

const Helper = {
	/**
	 * @param {string} title
	 * @param {Array|Object|string} log
	 */
	print: (title, log = []) => {
		if (ENV.isDev) {
			const logs = _.isArray(log) ? log : [log];
			console.group(`${title}`);
			logs.forEach((el) => console.log(el));
			console.groupEnd();
			console.log("\n");
		}
	},
	
	/**
	 * 更新 intl 翻譯
	 * @param {string} key 翻譯包 key
	 * @param {Object} value { zh : ... ,en : ... ,th : ... }
	 */
	updateTranslation: (key, value) => {
		//取得已有翻譯包語言
		const acceptLang = _.keys(Translations);
		//取得各語言 text
		_.forOwn(value, function (text, language) {
			const lang = acceptLang.filter((lang) =>
				lang.includes(language)
			);
			//如果 text 為支援語言寫入翻譯包
			if (!_.isNil(text) && lang.length >= 1) {
				Translations[lang[0]][key] = text;
			}
		});
	},
	acceptCountry: (country) => {
		const [defaultCountry = 'TW'] = Config.ACCEPT_COUNTRY.filter((code) =>
			code.includes(country)
		);
		return defaultCountry;
	},
	acceptLang: (language) => {
		const [defaultLang = "en"] = Config.ACCEPT_LANG.filter((lang) =>
			lang.includes(language)
		);
		return defaultLang;
	},
	getLocalPrice: (price) => {
		try {
			if (price < 25) {
				throw new Error(`Input Price = ${price}`);
			}
			switch (Config.COUNTRY) {
				case Country.TH:
					return price + 5;
				default:
					return price;
			}
		} catch (err) {
			throw new Error(
				`Error: Fail to Get Local Price From TWD: ${err}, must greater than or equal to 25`
			);
		}
	},
	waitFor: (milliseconds = 0, callback = func) =>
		new Promise((resolve, reject) => {
			if (milliseconds <= 0) {
				resolve(callback());
				return;
			}
			setTimeout(() => resolve(callback()), milliseconds);
		}),

	/**
	 * 更改Object propName. ex: 'camelCase', 'snakeCase', ...
	 * @param {object} obj
	 * @param {string} funcName - function name
	 * @param {function} adapterFunc - (key, value) => newValue
	 * @param {boolean} deepChange - 深層改變名稱
	 * @returns {object}
	 */
	changeCase: (data, funcName, adapterFunc, deepChange) => {
		// value 轉換器
		const adapter = _.isFunction(adapterFunc)
			? adapterFunc
			: (key, value) => value;

		// 指定的case function
		const caseName = funcName || "camelCase";

		// 限定 array or object
		if (!_.isObject(data)) return data;

		if (_.isArray(data)) {
			return data.map((item) =>
				Helper.changeCase(item, caseName, adapter, deepChange)
			);
		}

		const newObj = {};
		// Loop Object
		Object.keys(data).forEach((key) => {
			// key 轉換case
			const propName = ChangeCase[caseName](key);

			const value = data[key];

			let processedValue = adapter(propName, value);
			// value轉換 fallback
			if (_.isNil(processedValue)) {
				processedValue = value;
			}

			newObj[propName] = deepChange
				? Helper.changeCase(
					processedValue,
					caseName,
					adapter,
					deepChange
				)
				: processedValue;
		});

		return newObj;
	},

	getImageFromHtml: (elementId = "") => {
		console.log("getImageFromHtml : ", elementId);

		return new Promise((resolve, reject) => {
			Html2canvas(document.querySelector(`#${elementId}`), {
				useCORS: true,
				scale: 1,
			}).then((canvas) => {
				const url = canvas
					.toDataURL("image/png")
					.replace("image/png", "image/octet-stream");

				resolve(url);
			});
		});
	},

	getUrlParameter(url, nameProps) {
		let name = nameProps;
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
		const results = regex.exec(url);
		return results === null
			? ""
			: decodeURIComponent(results[1].replace(/\+/g, " "));
	},

	checkMail: (email) => {
		const regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
	},

	checkMobileNumber: function (number, country) {
		const result = isValidPhoneNumber(number, this.acceptCountry(country));
		return result;
	},

	checkTextWord: function (text) { //計算中英文字數 
		let realLength = 0;
		for (var i = 0; i < text.length; i++) {
			let charCode = text.charCodeAt(i);
			if (!(charCode >= 0 && charCode <= 128))
				realLength += 1;
		}
		let space = text.split(" ");

		return realLength + space.length - 1;
	},

	clearSpecialCharacter: (str = "") =>
		str.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ""),

	mergeByKey: (arr1, arr2, key) => {
		const criteria = {};
		criteria[key] = null;
		return _.map(arr1, (item) => {
			criteria[key] = item[key];
			return _.merge(item, _.find(arr2, criteria));
		});
	},

	/* 把object轉 array */
	objectToArray: (obj) => {
		if (obj && _.isPlainObject(obj)) {
			return _.compact(Object.values(obj));
		}
		return [];
	},

	// Object remove undefined or null value
	removeNil: (obj) => _.omitBy(obj, _.isNil),

	// Count Object keys that have value
	countObjectKeys: (obj) => {
		if (!_.isObject(obj)) return 0;
		let count = 0;

		Object.keys(obj).forEach((key) => {
			if (_.isObject(obj[key])) {
				count += Helper.countObjectKeys(obj[key]);
			} else if (_.isArray(obj[key]) && obj[key].length > 0) {
				count += 1;
			} else if (obj[key]) {
				count += 1;
			}
		});

		return count;
	},

	// Safe Parse Json
	parseJson: (str, fallback = {}) => {
		try {
			if (_.isObject(str)) {
				throw new Error("value is object");
			}

			const json = JSON.parse(str);
			const isSameType =
				(_.isArray(json) && _.isArray(fallback)) ||
				(_.isPlainObject(json) && _.isPlainObject(fallback));

			return isSameType ? json : fallback;
		} catch (error) {
			// 已經是object
			const isSameType =
				(_.isArray(str) && _.isArray(fallback)) ||
				(_.isPlainObject(str) && _.isPlainObject(fallback));

			return isSameType ? str : fallback;
		}
	},

	clearCharacter: (value, character) => {
		if (typeof value === "string") {
			// Clear hash
			const regex = new RegExp(character, "gi");
			return value.replace(regex, "");
		}
		return value;
	},

	clearHTMLtags: (value) => {
		if (typeof value === "string") {
			return value.replace(/<[^>]*>/gi, "");
		}
		return value;
	},
	// 合併 Array
	mergeArray: (objValue, srcValue) => {
		if (_.isArray(objValue)) {
			const value = objValue.concat(srcValue);
			return _.uniq(value);
		}
	},

	/**
	 * @deprecated use lodash.remove
	 */
	deleteArrayItem: (array, name, value) => {
		const tmpArray = [];
		array.forEach((item) => {
			if (item[name] !== value) {
				tmpArray.push(item);
			}
		});
		return tmpArray;
	},

	// 排列array items
	/* order : 'asc', 'desc' */
	orderArray: (data, order, orderBy) =>
		stableSort(data, getSorting(order, orderBy)),

	toBool: (value) => {
		if (value == false || value === "false" || value === "FALSE") {
			return false;
		}

		if (value == true || value === "true" || value === "TRUE") {
			return true;
		}

		return false;
	},

	getTime: () => {
		const time = new Date();
		const year = time.getYear();
		const month = time.getMonth();
		const date = time.getDate();

		return `${year}/${month}/${date}`;
	},

	/**
	 * 檢查副檔名
	 * @param {String} extName 檔案的副檔名
	 * @param {Array} validImageExt 接受的副檔名
	 */
	CheckExtension: (extName, validImageExt) => {
		let foundExt = false;
		for (let ext in validImageExt) {
			if (extName.toLowerCase() === validImageExt[ext]) {
				foundExt = true;
				break;
			}
		}
		return foundExt;
	},
	/**
	 * 轉換blob到base64
	 * @param {String} blob
	 */
	BlobToBase64: (blob) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		return new Promise((resolve) => {
			reader.onloadend = () => {
				resolve(reader.result);
			};
		});
	},
};

// MARK: For Array Order
function desc(a, b, orderby) {
	if (b[orderby] < a[orderby]) {
		return -1;
	}
	if (b[orderby] > a[orderby]) {
		return 1;
	}
	return 0;
}

function stableSort(data, cmp) {
	const array = _.isArray(data) ? data : [];
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === Order.DESC
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

export default Helper;
