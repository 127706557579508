/* eslint-disable no-template-curly-in-string */
let en = {
	"photo.selected": "Selected {number} ",
	"photo.next": "Next",
	"photo.noPhotos": "No Photos",
	"photo.select.limit": "minimum 2 photos, maximum 4 photos",
	"source.image": "Image",
	"upload.file": "Upload File",
	"upload.newest": "Newest",
	// action
	"action.cancel": "Cancel",
	"action.ok": "OK",
	"action.next": "Next",
	"action.back": "Back",
	"action.finish": "Finish",
	"action.select.all": "Select all",
	"action.Home": "Home",
	// edit
	"edit.title": "Edit Photo",
	"edit.tab.property": "Property",
	"edit.tab.frame": "Frame",
	"edit.tab.text": "Text",
	"edit.tab.location": "Location",
	"edit.tab.date": "Date",
	"edit.frameLoadmore": "More Frames",
	"edit.reset": "reset",
	"edit.layout": "layout",
	"edit.straight": "vertical",
	"edit.horizontal": "horizontal",
	"edit.full": "full",
	"edit.rotate": "rotate",
	"edit.center": "center",
	"edit.edit": "edit",
	"edit.editText": "Edit Text",
	"edit.editLocation": "Edit Location",
	"edit.editDate": "Edit Date",
	"edit.show": "show",
	"edit.hide": "hide",
	"edit.wordLimit": "Word Limit",
	"edit.clear": "Clear",
	"edit.progress": "Processing({current}/{total})",
	"edit.disable.frame": "No frame",
	"edit.frame.disable.text": "Cannot edit with frame",

	// Home
	"loadmore": "Load more",

	// frame
	"frame.title": "Search Frames",
	"frame.search": "Search",
	"frame.enterKeywords": "Enter keywords",
	"frame.hotIllustrators": "Hot Illustrators",
	"frame.hotKeywords": "Hot Keywords",
	"frame.illResult": "Illustrator Result",
	"frame.keywordResult": "Keyword Result",
	"frame.hot": "Hot",
	"frame.new": "New",
	"frame.random": "Random",
	"frame.noResult": "No Search Result",
	"frame.popular": "Popular",

	// checkout
	"checkout.title.frame": "Confirm the photo",
	"checkout.title.mask": "Mask Container",
	"checkout.title.creatorEvent": "Card",
	"checkout.terms": "Terms",
	"checkout.payment": "Payment",
	"checkout.receipt": "E-receipt",
	"checkout.coupon": "Coupon",
	"checkout.coupon.placeholder": "Coupon",
	"checkout.coupon.verify": "Verifty",
	"checkout.coupon.error": "invalid coupon",
	"checkout.coupon.used": "coupon is used",
	"checkout.coupon.machine.only": "coupon is machine only",
	"checkout.coupon.discount": "coupon discount: {discount}",
	"checkout.coupon.point": "MP point : {point}",
	"checkout.coupon.buy2get2": "Buy 2 get 2 photo",
	"checkout.unreached.discount": "Unreached discount amount",
	"checkout.coupon.exceed.limit": "exceed the limit",
	"checkout.coupon.expired": "coupon has expired",
	"checkout.coupon.new.user.only": "coupon only for new user",
	"checkout.priceTitle": "Consumption",
	"checkout.price": "{price_unit}{price} / {number} photos",
	"checkout.photo.quantity": "{number} photos",
	"checkout.photo.price": "photos {price_unit}{price}",
	"checkout.shipping.fee": "shipping fee {price_unit}{shippingFee} (Free Shipping for over {price_unit}{minimum})",
	"checkout.free.shipping": "Free Shipping",
	"checkout.no.event.frame":"Please select activity frame",
	"checkout.serialcode": "serial code is",
	"checkout.save.serialcode": "Please save this serial code",
	"checkout.save.serialcode.print": "請{highlight}記下你的序號{br}並至機台輸入序號完成列印！",
	"checkout.gophotopage": "Go photo page",
	"checkout.gohomepage": "Go home page",
	"checkout.sendMail": "Send Mail",
	"checkout.enterEmail": "Enter Email",
	"checkout.email": "Email",
	"checkout.send": "Send",
	"checkout.cannot.getEphoto": "Please complete the payment before requesting the image file",
	"checkout.emailWrongFormat": "Email Wrong Format",
	"checkout.sendEmailSuccess": "Send Email Successfully",
	"checkout.enterText": "Enter Text",
	"checkout.checkout": "Checkout",
	"checkout.linepay": "Linepay",
	"checkout.jkopay": "JKOPay",
	"checkout.creditcard": "Credit card",
	"checkout.ATM": "ATM",
	"checkout.easypay": "Easy Wallet",
	"checkout.onsite": "onsite",
	"checkout.mp.point": "MP POINT",
	"checkout.current.mp.point": "Current points",
	"checkout.tutorial.step": "Step ",
	"checkout.button.send": "Send",
	"checkout.ThaiQR":"PromptPay",
	"checkout.thaiQR.title": "Please take a screenshot of this page",
	"checkout.thaiQR.serialNumber": "Serial No:{serialNumber}",
	"checkout.thaiQR.steps":
		`1.Open Mobile Banking App or True Money Wallet App, then scan QRcode below to pay {amount} THB. Once you have completely paid, the transaction will be automatically updated in the system.
		{br}
		2. Check your order status and delivery info from Line ID: @fotoya
		{br}
		3.Follow Instagram fotoya.creators to win a free coupon.`,
	"checkout.thaiQR.price": "Order {quantity} photo(s) price {price} THB",
	"checkout.thaiQR.shippingFee": "Shipping fee {shippingFee} THB",
	"checkout.thaiQR.amount": "Total price ฿ {amount} THB",
	"checkout.event.ran.out":"Free print vouchers already ran out",
	"checkout.event.only.once":"Only print once for free",

	// alert
	"alert.reset.title": "Reset Photo",
	"alert.reset.message": "Are you sure you want to reset?",
	"alert.select.even.photos": "Please select an even number of photos",
	"alert.select.4.photos": "Please select multiple of four for the photo",
	"alert.select.max.photos": "Maximum {maximum} photos",
	"alert.insufficient.balance": "Insufficient balance",
	"alert.delivery.user.info.empty": "Shipping information cannot empty",
	"alert.delivery.mobile.wrong.format": "Mobile number format 0xxxxxxxxx",
	"alert.select.photo.empty": "Please select photo",
	"alert.select.mask.empty": "Please select mask container style",
	"alert.select.data.empty": "Please select items",
	//illustrator
	"illustrator.no.frame": "No frames",
	"illustrator.no.mask": "No face mask container",
	"illustrator.no.data": "No Data",
	"illustrator.goto.frame": "Print Photos",
	"illustrator.goto.mask":"Make Container",
	"illustrator.goto.location": "Location",
	"illustrator.reviews": "Reviews",
	"illustrator.frame": "Frame",
	"illustrator.mask": "Mask container",
	"illustrator.card": "Cards",
	"illustrator.goto.card":"Make Cards",

	"footer.about": "About Memopresso",
	"footer.intro":
		"Through distinguished social media and the integration of software and hardware, we adopt unmanned retail technology and furthermore cooperate with illustrators and creators, providing young users with a solution to quickly save, create and share personalized photos as well as creating an apparent social media sharing experience.",
	"footer.guideTitle": "Guide",
	"footer.guide.about": "About",
	"footer.guide.use": "How to use",
	"footer.guide.location": "Machine Loc",
	"footer.guide.hotCreator": "Hot Creator",
	"footer.guide.business": "Business",
	"footer.guide.become.creator": "Become creator",
	"footer.guide.lang": "Language",
	"footer.contact.tel": " ",
	"footer.contact.mail": " ",

	//Creator
	"creator.title": "Creators",
	"creator.search": "Search creators",
	"creator.weekHot": "Weekly Hot",
	"creator.monthHot": "Monthly Hot",
	"creator.new": "New",
	"creator.recommend": "Recommend",
	"creator.halloween": "Halloween",
	"creator.christmas": "Christmas",
	"creator.new.year": "NEW YEAR",
	"creator.graduation": "Graduation",
	"creator.ghost": "Ghost",
	"creator.mother": "Mother Day",
	"creator.father": "Father Day",
	"creator.others": "Others",
	"creator.rainbow": "Rainbow again",
	"creator.love": "Love",
	"creator.copy": "Copy Link",
	"creator.copySuccess": "Copy Success",
	"creator.shareFacebook": "Share to Facebook",

	//Manual
	"manual.title": "How to use",
	"manual.step1": "Log in to LINE, select photos and frames",
	"manual.step2": "Make payment and get serial number",
	"manual.step3": "Go to the nearby machine and enter the serial number",
	"manual.step4": "Get your photo",
	"manual.detail": "Detail Tutorial",

	// Location
	"location.title": "Machine Location",
	"location.openMap": "Open map",

	//Delivery 
	"delivery.option": "Delivery option",
	"delivery.delivery": "Delivery",
	"delivery.self": "Self",
	"delivery.input.name": "Name",
	"delivery.input.phoneNumber": "Phone Number",
	"delivery.input.address": "Address",
	"delivery.input.error.phoneNumber": "Error Phone Number",
	"delivery.input.remarks": "Remarks (limited to 20)",

	//Order
	"order.information": "Order information",
	"order.quantity": "Quantity",
	"order.photo.price": "Price",
	"order.shippingfee": "Shipping fee",
	"order.total.price": "Total price",
	"order.query": "Order query step ",
	"order.recipient": "Recipient",
	"order.address": "Address",
	"order.number": "Order number",
	"order.delivery.time": "Photos will be sent within five working days",
	"order.receipt.donate": "Donate",
	"order.receipt.no.donate": "No Donate",
	"order.receipt.no.setting": "Please setup on the machine",

	//Reviews
	"reviews.no.data": "No Data",
	"reviews.no.reviews": "No reviews yet",
	"reviews.select.frame": "Select Frame",
	"reviews.content": "Reviews",
	"reviews.selectframe.or.selectall": "Select Frames or select all",
	"reviews.contiune": "Continue to comment other frame",
	"reviews.yes": "OK",
	"reviews.no": "NO",
	"reviews.finish": "Finished",
	"reviews.content.empty": "Content cannot empty",
	"reviews.star": "Please select star",
	"reviews.default.cute": "So cute",
	"reviews.default.like": "Like",
	"reviews.default.useful": "Useful",
	"reviews.content.placeholder": "Write a review",

	//ZipCodeSelect
	"zipCodeSelect.zipCode": "ZIP Code",
};

export default en;
