import Config from "constants/config";
import _ from "lodash";
import QueryString from "query-string";
import Axios from "axios";
import qs from "qs";
import Helper from "utils/helper";
// constant
import { FrameType } from "constants/photo";

Axios.defaults.withCredentials = false;

const MemoAPI = {
	getUploadPhoto: async (userId = "") => {
		try {
			const URL = `${Config.MEMO_URL}/user/photo/${userId}`;
			const {
				data: { photoList = [] },
			} = await Axios.get(URL);
			return photoList;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	uploadImageFile: async (dataSet) => {
		try {
			const {
				data: { photoList = [] },
			} = await Axios.post(`${Config.MEMO_URL}/user/upload`, dataSet);
			return photoList;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	getLocation: async () => {
		try {
			const { data } = await Axios.get(
				`${Config.MEMO_URL}/machine/list/TAIWAN`
			);
			const list = {
				double: [],
				north: [],
				west: [],
				south: [],
				east: [],
			};

			_.map(data, (el) => {
				const { city } = el;
				switch (city) {
					// 雙北市
					case "台北市":
					case "新北市":
						list.double.push(el);
						break;
					// 北部
					case "桃園市":
					case "基隆市":
					case "新竹市":
						list.north.push(el);
						break;
					// 中部
					case "台中市":
					case "雲林縣":
					case "南投縣":
						list.west.push(el);
						break;
					// 南部
					case "嘉義縣":
					case "嘉義市":
					case "台南市":
					case "高雄市":
					case "屏東縣":
						list.south.push(el);
						break;
					default:
						list.east.push(el);
						break;
				}
			});

			return list;
		} catch (e) {
			throw new Error(`Error: Fail to get machine location: ${e}`);
		}
	},
	/**
	 * 拿取相框首頁資訊
	 * @param {string} params.lang (zhtw, eng)
	 * @param {string} params.keyword
	 * @return {object} result
	 */
	searchFramesInfo: async (params = {}) => {
		try {
			let body = {
				lang: params.lang !== "en" ? "zhtw" : params.lang,
				keyword: params.keyword,
				country: Config.COUNTRY
			};

			const {
				data: { illustrator = [], keyword = [] },
			} = await Axios.post(
				`${Config.MEMO_URL}/illustrator/search`,
				QueryString.stringify(body)
			);
			return {
				illustrator: Helper.changeCase(illustrator, "camelCase"),
				keyword: Helper.changeCase(keyword, "camelCase"),
			};
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/**
	 *
	 * 拿取相框種類 (熱門、最新、隨機)
	 * @param {string} body.type NEW / HOT / RANDOM / EVENT (default)
	 * @return {object} result
	 */
	getFramesByType: async (type = FrameType.EVENT) => {
		try {
			const body = {
				type,
				country: Config.COUNTRY
			};
			const {
				data: { frame = [] },
			} = await Axios.post(
				`${Config.MEMO_URL}/illustrator/frame`,
				QueryString.stringify(body)
			);
			return Helper.changeCase(frame, "camelCase");
		} catch (e) {
			throw new Error(`Error: Fail to get frames : ${e}`);
		}
	},
	/**
	 * 搜尋插畫家相框(by ID)
	 * @param {string} illustratorId
	 * @return {object} result
	 */
	searchFrameByIllustrator: async (illustratorId = "") => {
		try {
			const body = {
				type: FrameType.ILLUSTRATOR,
				keyword: illustratorId,
				country: Config.COUNTRY
			};
			const {
				data: { frame = [] },
			} = await Axios.post(
				`${Config.MEMO_URL}/illustrator/frame`,
				QueryString.stringify(body)
			);
			return Helper.changeCase(frame, "camelCase");
		} catch (e) {
			throw new Error(
				`Error: Fail to search frame by illustrator id: ${e}`
			);
		}
	},
	/**
	 * 搜尋插畫家相框(by Username)
	 * @param {string} illustratorId
	 * @return {object} result
	 */
	searchFrameByIllustratorUsername: async (username = "") => {
		try {
			const body = {
				type: FrameType.USERNAME,
				keyword: username,
				country: Config.COUNTRY
			};
			const {
				data: { frame = [] },
			} = await Axios.post(
				`${Config.MEMO_URL}/illustrator/frame`,
				QueryString.stringify(body)
			);
			return Helper.changeCase(frame, "camelCase");
		} catch (e) {
			throw new Error(
				`Error: Fail to search frame by illustrator id: ${e}`
			);
		}
	},
	/**
	 * 搜尋相框關鍵字
	 * @param {string} keyword
	 * @return {object} result
	 */
	searchFrameByKeyword: async (keyword = "") => {
		try {
			const body = {
				type: FrameType.SEARCH,
				keyword,
				country: Config.COUNTRY
			};
			const {
				data: { frame = [] },
			} = await Axios.post(
				`${Config.MEMO_URL}/illustrator/frame`,
				QueryString.stringify(body)
			);
			return Helper.changeCase(frame, "camelCase");
		} catch (e) {
			throw new Error(`Error: Fail to search frame by keyword: ${e}`);
		}
	},
	sendPhotoOrders: async (dataSet) => {
		try {
			const {
				data
			} = await Axios.post(
				`${Config.MEMO_URL}/user/sendOrders`,
				qs.stringify(dataSet)
			);
			console.log(data);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	sendPrintPhoto: async (dataSet) => {
		try {
			const {
				data: { result = true },
			} = await Axios.post(
				`${Config.MEMO_URL}/user/uploadphoto`,
				qs.stringify(dataSet)
			);
			return result;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	requestlinepay: async (dataSet) => {
		try {
			const {
				data: { info = {} },
			} = await Axios.post(
				`${Config.MEMO_URL}/user/linepay`,
				qs.stringify(dataSet)
			);
			return info;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	confirmlinepay: async (dataSet) => {
		try {
			const {
				data: { isPaid = 0 },
			} = await Axios.post(
				`${Config.MEMO_URL}/user/confirmlinepay`,
				qs.stringify(dataSet)
			);
			return isPaid;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	confirmJKOPay: async (dataSet) => {
		try {
			const {
				data,
			} = await Axios.post(
				`${Config.BASE_URL}/jkopay/inquiryOrder`,
				dataSet
			);
			return data[0].status === 'success';
		} catch (e) {
			throw new Error(`Error: Fail to confirm JKOPay status: ${e}`);
		}
	},
	updatePhotoContent: async (dataSet) => {
		try {
			const {
				data: { result = true },
			} = await Axios.post(
				`${Config.MEMO_URL}/user/phototext`,
				qs.stringify(dataSet)
			);
			return result;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	/* 取得創作者個人資訊(含像框 照片) */
	getIllustratorFrame: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/illustrator/profile`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 取得創作者個人資訊(不含像框 照片) */
	getCreatorInfo: async (userId = "") => {
		try {
			const { data } = await Axios.get(
				`${Config.MEMO_URL}/illustrator/profileOnly/${userId}`
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 取得創作者照片 */
	getCreatorImage: async (userId = "") => {
		try {
			const { data: { images = {} } } = await Axios.get(
				`${Config.MEMO_URL}/creator/image/${userId}`
			);
			return images;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	getUserBalance: async (userId = "") => {
		try {
			const {
				data: { balance = 0 },
			} = await Axios.get(`${Config.MEMO_URL}/user/balance/${userId}`);
			return balance;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 推播列印序號訊息 */
	pushMessageSerialcode: async (serialCode = "") => {
		try {
			const {
				data: { result = true },
			} = await Axios.get(
				`${Config.MEMO_URL}/member/pushSerialcode/${serialCode}`
			);
			return result;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* log record */
	logRecord: async (dataSet) => {
		try {
			const {
				data: { result = true },
			} = await Axios.post(
				`${Config.MEMO_URL}/log/record`,
				qs.stringify(dataSet)
			);
			return result;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	/* 取得訂單資訊 */
	getOrderInfo: async (code = "") => {
		try {
			const {
				data: { order = {} },
			} = await Axios.get(`${Config.MEMO_URL}/user/orderinfo/${code}`);
			return order;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 取得物流訂單資訊 */
	getDeliveryOrderInfo: async (code = "") => {
		try {
			const {
				data: { orders = {} },
			} = await Axios.get(`${Config.MEMO_URL}/user/deliveryOrderInfo/${code}`);
			return orders;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 首頁 相框專區與隨機顯示插畫家 */
	getCreatorList: async () => {
		try {
			const { data } = await Axios.get(
				`${Config.MEMO_URL}/illustrator/list`
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 專區相框 */
	getTagFrames: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/illustrator/tags`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 全家口罩活動 */
	getMaskContainers: async (illustrator) => {
		try {
			const { data } = await Axios.get(
				`${Config.BASE_URL}/homepage/maskContainers`,
				{ params: { illustrator } }
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get mask containers: ${e}`);
		}
	},
	/* 全家畢業卡片活動 */
	getFamiEvent: async (illustrator) => {
		try {
			const { data } = await Axios.get(
				`${Config.BASE_URL}/homepage/famiEvent`,
				{ params: { illustrator } }
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get mask containers: ${e}`);
		}
	},
	/* 首頁搜尋 */
	searchCreatorList: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/illustrator/searchlist`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/* 傳送信件 */
	/*sendEmail: async (email = [], text = "", code = "") => {
		try {
			const { data } = await Axios.post(
				`https://studio.memopresso.com/api/mp/sendMail`,
				{
					email,
					text,
					code,
				}
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},*/
	sendEmail: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/user/sendmail`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get email : ${e}`);
		}
	},
	/* 取得活動列印照片 */
	getEventPhoto: async (type = "") => {
		try {
			const {
				data: { photoList = [] },
			} = await Axios.get(
				`${Config.MEMO_URL}/photo/event/${type}`
			);
			return photoList;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 更新會員資料 */
	updateUserInfo: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/user/update`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/**
	 * 讀取更多創作者資料
	 * @param {object} cursor { imp, id }
	 * @return {object} result
	 */
	getCreators: async (cursor = {}) => {
		try {
			const { data } = await Axios.post(
				`https://data.memopresso.com/api/illustrator/list`,
				qs.stringify(cursor)
			);
			let { data: results = [] } = data;
			const list = _.map(results, (el) => {
				const {
					illustrator_id,
					imp,
					link_name,
					logo,
					name,
				} = el;
				return {
					id: illustrator_id,
					imp: parseInt(imp),
					link_name,
					logo,
					name,
				};
			});
			return list;
		} catch (e) {
			throw new Error(`Error: Fail to load more creators : ${e}`);
		}
	},
	/* ATM 付款取號 */
	getAtmPaymentInfo: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/payment/atmData`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/* 後台產生合成圖片 */
	exportPhoto: async (photoData = []) => {
		try {
			const { data } = await Axios.post(
				`https://studio.memopresso.com/api/mp/genPhoto`,
				{
					photoData
				}
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get export photo : ${e}`);
		}
	},
	/* 建立悠遊付訂單 */
	requestEasyWallet: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				Config.BASE_URL + `/easypay/creatOrder`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	/* 建立街口支付訂單 */
	requestJKOPay: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				Config.BASE_URL + `/jkopay/creatOrder`,
				dataSet
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to creat JKOPay transaction: ${e}`);
		}
	},
	/* 建立Thai QR支付訂單 */
	requestTHAIQRPay: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				Config.BASE_URL + `/thaiqrpay/creatOrder`,
				dataSet
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to creat THAIQRPay transaction: ${e}`);
		}
	},
	/* 取得IG hashtag照片 */
	getHashtagPhoto: async (hashtag = "", cursor = "") => {
		try {
			const { data } = await Axios.get(
				//`https://www.instagram.com/explore/tags/eventtaiwan/?__a=1`
				`https://data.memopresso.com/api/event/instagram/mini`
				//`https://www.instagram.com/graphql/query/?query_hash=9b498c08113f1e09617a1703c22b2f32&variables={"tag_name":"${hashtag}","first":"5","after":"${cursor}"}`
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
			//throw new Error('Error');
		}
	},
	getHashtagMultiPhoto: async (shortcode = "") => {
		try {
			const { data } = await Axios.get(
				`https://www.instagram.com/graphql/query/?query_hash=2418469a2b4d9b47ae7bec08e3ec53ad&variables={"shortcode":"${shortcode}"}`
			);
			console.log(data)
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	checkCoupon: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/coupon/verify/`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/* 取得消費者留言 */
	getReviews: async (dataSet) => {
		try {
			const { data } = await Axios.get(
				`${Config.BASE_URL}/creator/reviews`,
				{ params: dataSet }
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get framesInfo : ${e}`);
		}
	},
	/* upload reviews image */
	uploadReviewsImage: async (dataSet) => {
		try {
			const {
				data: { photo = [] },
			} = await Axios.post(`${Config.MEMO_URL}/reviews/upload`, dataSet);
			return photo;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	/* send reviews content */
	sendReviews: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/reviews/add`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	getOrderPhoto: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.MEMO_URL}/reviews/order`,
				qs.stringify(dataSet)
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to upload photo: ${e}`);
		}
	},
	/* 取得首頁Banner 與 熱門分類推薦 */
	getBanner: async (country) => {
		try {
			const { data } = await Axios.get(
				`${Config.BASE_URL}/homepage/banners`,
				{ params: { country } }
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get banners: ${e}`);
		}
	},
	/* 取得首頁Banner 與 熱門分類推薦 */
	getCategories: async (country) => {
		try {
			const { data } = await Axios.get(
				`${Config.BASE_URL}/homepage/categories`,
				{ params: { country } }
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get categories: ${e}`);
		}
	},
	/* 取得使用者地區資訊 */
	getUserRegion: async () => {
		try {
			const { data } = await Axios.get(
				Config.GEO_LOCATION_URL
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get UserRegion: ${e}`);
		}
	},
	/* 檢查是否有使用活動的免費列印 */
	checkUserEventPrint: async (account = "") => {
		try {
			const { data } = await Axios.get(
				`${Config.MEMO_URL}/user/getEventPrint/${account}`
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get photo: ${e}`);
		}
	},
	/* 送出口罩收納盒訂單 */
	addMaskOrder: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.BASE_URL}/order/addMaskOrder`,
				dataSet
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to add mask order: ${e}`);
		}
	},
	/* 送出creatorEvent訂單 */
	addCreatorEventOrder: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				`${Config.BASE_URL}/order/addCreatorEventOrder`,
				dataSet
			);
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to add mask order: ${e}`);
		}
	},
	/* 更新 captureclub 圖片上傳 */
	updateUploadPhotoFromCaptureclub: async (dataSet) => {
		try {
			const { data } = await Axios.post(
				"https://image.memopresso.com/api/capture/photo/", QueryString.stringify(dataSet));
			return data;
		} catch (e) {
			throw new Error(`Error: Fail to get frames : ${e}`);
		}
	},
};

export default MemoAPI;
