import LiffHelper from 'utils/liffHelper';
import _ from 'lodash';
import LSKey from "constants/localStorageKey";
//API
import MemoAPI from "services/memoAPI";
import Helper from 'utils/helper';
import Config from "constants/config";

export const CHANGE_LANG = 'CHANGE_LANG'
export const GET_LINE_PROFILE = 'GET_LINE_PROFILE'
export const SET_REGION = 'SET_REGION'
export const SET_PRODUCT = 'SET_PRODUCT'

const func = () => { }

export const changeLang = lang => ({
    type: CHANGE_LANG,
    payload: lang,
})

export const setRegion = () => {
    return async dispatch => {
        let language = localStorage.getItem(LSKey.Language);
        const info = await MemoAPI.getUserRegion();
        if (_.isNull(language)) {
            language = Helper.acceptLang(info.language);
        }
        dispatch({
            type: SET_REGION,
            payload: { region: info, lang: language }
        });
    }
}
export const getLineProfile = (callback = func) => async dispatch => {

    await LiffHelper.init()
    const profile = await LiffHelper.getProfile()
    
    //console.log('Profile : ', profile)

    callback(profile, null)

    return dispatch({
        type: GET_LINE_PROFILE,
        payload: profile,
    })
}
