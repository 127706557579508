import MemoAPI from "services/memoAPI";
import _ from "lodash";
import Config from "constants/config";
// for dev
// import photoResp from "sample/photo.json";

export const GET_PHOTO = "GET_PHOTO";
export const SET_PHOTO = "SET_PHOTO";

const func = () => { };
/**
 * 獲取user photo
 * @param callback - (data, error)
 * @return {function(*)} - action
 */
export const getPhoto = (userId = "", callback = func) => async (dispatch) => {
	// for dev
	// let list = _.mapKeys(photoResp.photoList, 'photoSerial')
	if (!userId) {
		callback([], null);
		return dispatch({
			type: GET_PHOTO,
			payload: [],
		});
	}

	const photoList = await MemoAPI.getUploadPhoto(userId);
	const list = _.mapKeys(photoList, "photoSerial");

	/* 加入check屬性 */
	_.map(list, (el) => {
		//el.checked = photoList[0].create_date === el.create_date; //不幫忙預設勾選
		el.checked = false; //不幫忙預設勾選
		el.price = Config.PHOTO_PRICE;
	});

	callback(list, null);

	return dispatch({
		type: GET_PHOTO,
		payload: list,
	});
};

/**
 * 更新 Photo 屬性
 * @param {object} list
 * @param {function(*)} callback
 * @return {function(*)}
 */
export const setPhoto = (list, callback = func) => (dispatch) => {
	callback(list, null);
	return dispatch({
		type: SET_PHOTO,
		payload: list,
	});
};
