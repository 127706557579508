import { Country } from './general'
const ENV = {
	isDev:
		process.env.NODE_ENV !== "production" ||
		window.origin.includes("dev.memopresso.com"),
	isProd: process.env.NODE_ENV === "production",
};

const Config = {
	COUNTRY: null,
	set SET_COUNTRY(country) {
		this.COUNTRY = country;
	},
	//LINE_ID: "1653956261-OG5ZVVXZ",
	get LINE_ID() {
		if (window.origin.includes("th.memopresso.com") || this.COUNTRY === Country.TH)
			return '1655988635-96nlgGlX';
		else
			return '1653956261-OG5ZVVXZ';
		/*
		switch (this.COUNTRY) {
			case Country.TH:
				return '1655988635-dbGpmnp0';//'1655988635-MmLaz5a3';
			//case null:
			//	return '';
			default:
				return '1653956261-OG5ZVVXZ';
		}*/
	},
	LINE_DEV_ID: "1653956261-4GxmZZam",
	MEMO_URL: `https://data.memopresso.com/api`,
	get PHOTO_PRICE() {
		switch (this.COUNTRY) {
			case Country.TH:
				return 30;
			// case Country.KR:
			// 	return 1500;
			default:
				return 25;
		}
	}, //window.location.pathname.includes("event") ? 1500 : 25,
	get PRICE_UNIT() {
		switch (this.COUNTRY) {
			case Country.TH:
				return "฿";
			// case Country.KR:
			// 	return "₩";
			default:
				return "NT$";
		}
	}, //window.location.pathname.includes("event") ? "₩" : "$",
	get FREE_SHIPPING_MIN() {
		switch (this.COUNTRY) {
			case Country.TH:
				return 300;
			default:
				return 200;
		}
	},
	get SHIPPING_FEE() {
		switch (this.COUNTRY) {
			case Country.TH:
				return 30;
			default:
				return 60;
		}
	},
	GA_ID: "UA-113783728-3",
	GA_ID_DEV: "UA-113783728-4",
	FACEBOOK_PIXEL_ID: "576171353290557",
	FACEBOOK_PIXEL_ID_ONLINE: "3056619431087914",
	FACEBOOK_PIXEL_ID_ANII: "1469649393216365",
	BASE_URL:
		process.env.NODE_ENV !== "production"
			? "http://localhost:3000"
			: `https://studio.memopresso.com/api`,
	GEO_LOCATION_URL: "https://asia-east2-analog-provider-226605.cloudfunctions.net/geo-location",
	ORDER_URL: "https://creator.memopresso.com/order/",
	ACCEPT_LANG: ["en", "zh-tw", "th"],
	ACCEPT_COUNTRY: ['TW', 'TH'],
};
// store.subscribe(Config.PHOTO_PRICE);
export { ENV };
export default Config;
