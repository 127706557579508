/* eslint-disable no-template-curly-in-string */
let zh_tw = {
	"photo.selected": "已選 {number} 張",
	"photo.next": "下一步",
	"photo.noPhotos": "尚未上傳照片",
	"photo.select.limit": "請選擇至少2張，最多選擇4張",
	"source.image": "媒體庫",
	"upload.file": "上傳照片",
	"upload.newest": "最新上傳",
	// action
	"action.cancel": "取消",
	"action.ok": "確定",
	"action.next": "下一步",
	"action.back": "上一步",
	"action.finish": "完成",
	"action.select.all": "全選",
	"action.Home": "首頁",
	// edit
	"edit.title": "編輯相片",
	"edit.tab.property": "屬性",
	"edit.tab.frame": "相框",
	"edit.tab.text": "文字",
	"edit.tab.location": "地標",
	"edit.tab.date": "日期",
	"edit.frameLoadmore": "更多相框",
	"edit.reset": "重設",
	"edit.layout": "版型",
	"edit.straight": "直式",
	"edit.horizontal": "橫式",
	"edit.full": "滿版",
	"edit.center": "置中",
	"edit.rotate": "旋轉",
	"edit.edit": "編輯",
	"edit.editText": "編輯文字",
	"edit.editLocation": "編輯地標",
	"edit.editDate": "編輯日期",
	"edit.show": "顯示",
	"edit.hide": "隱藏",
	"edit.wordLimit": "字數限制",
	"edit.clear": "清空",
	"edit.progress": "照片輸出中({current}/{total})",
	"edit.disable.frame": "此版型沒有相框",
	"edit.frame.disable.text": "此版型或相框 無法編輯文字",

	// Home
	"loadmore": "讀取更多",

	// frame
	"frame.title": "搜尋相框",
	"frame.search": "搜尋",
	"frame.enterKeywords": "輸入關鍵字",
	"frame.hotIllustrators": "熱門插畫家",
	"frame.hotKeywords": "熱門關鍵字",
	"frame.illResult": "插畫家結果",
	"frame.keywordResult": "關鍵字結果",
	"frame.hot": "熱門",
	"frame.new": "最新",
	"frame.random": "隨機",
	"frame.noResult": " 無搜尋結果",
	"frame.popular": "熱門推薦",

	// checkout
	"checkout.title.frame": "確認照片",
	"checkout.title.mask": "口罩收納夾",
	"checkout.title.creatorEvent": "卡片",
	"checkout.terms": "服務條款",
	"checkout.payment": "付款方式",
	"checkout.receipt": "電子發票",
	"checkout.coupon": "優惠序號",
	"checkout.coupon.placeholder": "優惠序號",
	"checkout.coupon.verify": "驗證",
	"checkout.coupon.error": "錯誤的序號",
	"checkout.coupon.used": "序號已使用過",
	"checkout.coupon.machine.only": "序號限機台輸入使用",
	"checkout.coupon.discount": "折扣金額: {discount}",
	"checkout.coupon.point": "MP 點數回饋 : {point}",
	"checkout.coupon.buy2get2": "此為買一送一序號,請再多選兩張照片",
	"checkout.unreached.discount": "消費未達折扣金額",
	"checkout.coupon.exceed.limit": "序號已超過可使用人數",
	"checkout.coupon.expired": "序號已過期",
	"checkout.coupon.new.user.only": "序號限新會員首次下單使用",
	"checkout.priceTitle": "消費總金額",
	"checkout.price": "{price_unit}{price} / 共{number}張",
	"checkout.photo.quantity": "共 {number} 張",
	"checkout.photo.price": "照片 {price_unit}{price}",
	"checkout.shipping.fee": "運費 {price_unit}{shippingFee} (消費滿{price_unit}{minimum}免運費)",
	"checkout.free.shipping": "免運",
	"checkout.no.event.frame": "未選取活動像框",
	"checkout.serialcode": "列印序號為",
	"checkout.save.serialcode": "請記下此列印序號",
	"checkout.save.serialcode.print": "請{highlight}記下你的序號{br}並至機台輸入序號完成列印！",
	"checkout.gophotopage": "回到照片頁面",
	"checkout.gohomepage": "回到首頁",
	"checkout.sendMail": "寄送照片電子檔",
	"checkout.enterEmail": "輸入電子信箱",
	"checkout.email": "電子信箱",
	"checkout.send": "寄送",
	"checkout.cannot.getEphoto": "需完成付款 才可索取檔案",
	"checkout.emailWrongFormat": "電子信箱格式錯誤",
	"checkout.sendEmailSuccess": "信件傳送完成",
	"checkout.enterText": "輸入文字",
	"checkout.checkout": "結帳",
	"checkout.linepay": "Linepay",
	"checkout.jkopay": "街口支付",
	"checkout.creditcard": "信用卡",
	"checkout.ATM": "ATM轉帳",
	"checkout.easypay": "悠遊付",
	"checkout.onsite": "機台支付",
	"checkout.mp.point": "MP點數",
	"checkout.current.mp.point": "目前點數",
	"checkout.tutorial.step": "步驟",
	"checkout.button.send": "送出",
	"checkout.ThaiQR": "PromptPay",
	"checkout.thaiQR.title": "Please take a screenshot of this page",
	"checkout.thaiQR.serialNumber": "Serial Number : {serialNumber}",
	"checkout.thaiQR.steps":
		`1. Open Mobile Banking App or True Money Wallet App, then scan QRcode below to pay {amount} THB
		{br}
		2. customer service Line ID: @fotoyashop
		{br}
		3.Follow Instagram fotoya.creators to win the free coupon`,
	"checkout.thaiQR.price": "Order {quantity} photo(s) price {price} THB",
	"checkout.thaiQR.shippingFee": "Shipping fee {shippingFee} THB",
	"checkout.thaiQR.amount": "Total price ฿ {amount} THB",
	"checkout.event.ran.out":"免費名額已使用完畢 ",
	"checkout.event.only.once":"一個人只能免費列印一次",

	// alert
	"alert.reset.title": "重設照片",
	"alert.reset.message": "確定要重設嗎？",
	"alert.select.even.photos": "照片張數請選擇雙數",
	"alert.select.4.photos": "照片張數請選擇4的倍數",
	"alert.select.max.photos": "最多選擇{maximum}張",
	"alert.insufficient.balance": "餘額不足,請先加值",
	"alert.delivery.user.info.empty": "寄件資訊不可空白",
	"alert.delivery.mobile.wrong.format": "手機號碼格式錯誤(格式為 09xxxxxxxx)",
	"alert.select.photo.empty": "請選擇照片",
	"alert.select.mask.empty": "請選擇收納盒樣式",
	"alert.select.data.empty": "請選擇項目",

	//illustrator
	"illustrator.no.frame": "尚未有相框",
	"illustrator.no.mask": "尚未有口罩收納盒",
	"illustrator.no.data": "尚未有資訊",
	"illustrator.goto.frame": "製作照片",
	"illustrator.goto.mask": "製作收納盒",
	"illustrator.goto.location": "機台位置",
	"illustrator.reviews": "評論",
	"illustrator.frame": "相框",
	"illustrator.mask": "口罩收納盒",
	"illustrator.card": "卡片",
	"illustrator.goto.card":"製作卡片",

	// Footer
	"footer.about": "關於 Memopresso",
	"footer.intro":
		"透過個性化社群軟體與軟硬整合，無人零售的技術，插畫家、創作者合作，提供年輕一代使用者快速保存，製作與分享個性化相片的解決方案，打造“有感社交” 的社群分享體驗。",
	"footer.guideTitle": "網頁導覽",
	"footer.guide.about": "關於",
	"footer.guide.use": "使用說明",
	"footer.guide.location": "機台地點",
	"footer.guide.hotCreator": "熱門創作者",
	"footer.guide.business": "商業合作",
	"footer.guide.become.creator": "成為創作者",
	"footer.guide.lang": "語言",
	"footer.contact.tel": "客服電話: 02-37655060",
	"footer.contact.mail": "客服信箱: business@memopresso.com",

	//Creator
	"creator.title": "創作者專區",
	"creator.search": "搜尋創作者",
	"creator.weekHot": "本週熱門",
	"creator.monthHot": "本月熱門",
	"creator.new": "新進創作",
	"creator.recommend": "推薦專區",
	"creator.halloween": "萬聖節專區",
	"creator.christmas": "聖誕節專區",
	"creator.new.year": "新年專區",
	"creator.graduation": "畢業季專區",
	"creator.ghost": "鬼月專區",
	"creator.mother": "母親節專區",
	"creator.father": "父親節專區",
	"creator.others": "其他專區",
	"creator.rainbow": "看見彩虹",
	"creator.love": "情人專區",
	"creator.copy": "複製連結",
	"creator.copySuccess": "複製成功",
	"creator.shareFacebook": "分享於Facebook",

	//Manual
	"manual.title": "使用說明",
	"manual.step1": "登錄LINE，選相片和相框",
	"manual.step2": "付款並取得序號",
	"manual.step3": "前往附近機台 點擊LINE按鈕並輸入序號",
	"manual.step4": "取得您的照片",
	"manual.detail": "詳細教學",

	// Location
	"location.title": "機台地點",
	"location.openMap": "開啟地圖",

	//Delivery 
	"delivery.option": "運送方式",
	"delivery.delivery": "郵寄",
	"delivery.self": "機台自取",
	"delivery.input.name": "姓名",
	"delivery.input.phoneNumber": "電話",
	"delivery.input.address": "地址",
	"delivery.input.error.phoneNumber": "電話格式錯誤",
	"delivery.input.remarks": "備註(有什麼話想留言,限20字)",

	//Order
	"order.information": "訂單資訊",
	"order.quantity": "照片數量",
	"order.photo.price": "照片金額",
	"order.shippingfee": "運費",
	"order.total.price": "總金額",
	"order.query": "訂單查詢 步驟 ",
	"order.recipient": "收件人",
	"order.address": "收件地址",
	"order.number": "訂單編號",
	"order.delivery.time": "照片會於三至五個工作天內寄出",
	"order.receipt.donate": "捐贈",
	"order.receipt.no.donate": "不捐贈",
	"order.receipt.no.setting": "請在機台付款時輸入資訊",

	//Reviews
	"reviews.no.data": "沒有資料",
	"reviews.no.reviews": "尚未有評論",
	"reviews.select.frame": "選擇要評論的相框",
	"reviews.content": "評論內容",
	"reviews.selectframe.or.selectall": "請選擇你想評論的相框 或是全選統一評論",
	"reviews.contiune": "請問是否要繼續評論其他相框",
	"reviews.yes": "好",
	"reviews.no": "不要了",
	"reviews.finish": "已完成所有相框的評論",
	"reviews.content.empty": "請輸入文字內容或是選擇預設文字內容",
	"reviews.star": "請選擇幾顆星",
	"reviews.default.cute": "相框好可愛",
	"reviews.default.like": "好喜歡",
	"reviews.default.useful": "很實用",
	"reviews.content.placeholder": "請輸入評論內容",

	//ZipCodeSelect
	"zipCodeSelect.zipCode": "郵遞區號",
};

export default zh_tw;
