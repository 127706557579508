import MemoAPI from "services/memoAPI";
import _ from "lodash";
import Config from "constants/config";


export const SET_MASK = "SET_MASK";


/**
 * 設定已選收納盒樣式
 * @param {object} list
 */
export const setMask = list =>  ({
    type: SET_MASK,
    payload: list,
});
