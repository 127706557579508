import _ from 'lodash'
import LSKey from 'constants/localStorageKey'
import { GET_CREATOR, SET_CREATOR } from 'actions/creator'

/**
 * creator: {
 *    
 * }
 * @param state
 * @param action
 * @return {*}
 */
const creatorReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CREATOR: {
            //creator 
            const creatorInfo = action.payload
        
            // no data
            if (_.isEmpty(creatorInfo)) {
                return {
                    ...state,
                    creatorInfo
                }
            }
 
            return {
                ...state,
                creatorInfo
            }
        }

        case SET_CREATOR: {
            // new data 
            const creatorInfo = action.payload

            return {
                ...state,
                creatorInfo
            }
        }

        default:
            return state
    }
}

export default creatorReducer
