import _ from 'lodash'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import generalReducer from 'reducers/general'
import photoReducer from 'reducers/photo'
import creatorReducer from 'reducers/creator'
import maskReducer from 'reducers/mask'
const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        general: generalReducer,
        photo: photoReducer,
        creator: creatorReducer,
        mask: maskReducer
    })

const createRootReducer = history => (state, action) => {
    return appReducer(history)(state, action)
}

export default createRootReducer
